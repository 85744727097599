import { InjectionToken } from '@angular/core'

import { COMMIT_HASH } from './commit-hash'
import { VersionInfo, SemanticVersion } from './version-type'

const VERSION_MAJOR_NUMBER = 2 // Only increase this number when introducing breaking changes
const VERSION_MINOR_NUMBER = 12 // Only increase this number when introducing non-breaking change features
const VERSION_PATCH_NUMBER = 12 // Increase this number for bug fixing

// App version in dictionary format, easy to run code logic on
export const APP_VERSION = new InjectionToken<VersionInfo>('app.version')
// App version in text format to show to human
export const APP_VERSION_STRING = new InjectionToken<string>('app.version_string')

export const CURRENT_VERSION: VersionInfo = {
  versionNumber: {
    major: VERSION_MAJOR_NUMBER,
    minor: VERSION_MINOR_NUMBER,
    patch: VERSION_PATCH_NUMBER
  },
  commitHash: COMMIT_HASH
}

/**
 * Construct a human readable app version based on the provided versionInfo + environment.
 * Commit hash and stage name will not be included if the environment is production.
 *
 * @param versionInfo information of current version
 * @param environment current environment of the system (prod, qa, dev, etc.)
 * @return human readable version, for example: 1.0.0-<commit_hash>
 */
export function constructVersionString(versionInfo: VersionInfo, environment: string): string {
  const semanticVersionString = constructSemanticVersion(versionInfo.versionNumber)
  return `${semanticVersionString}-${versionInfo.commitHash}`
  }


function constructSemanticVersion(versionNumber: SemanticVersion): string {
  const versionSeparator = '.'
  return [
    versionNumber.major,
    versionNumber.minor,
    versionNumber.patch,
  ].join(versionSeparator)
}
