import { Injectable } from '@angular/core'
import { Store } from '@ngxs/store'
import { combineLatest, merge } from 'rxjs'
import { filter, map } from 'rxjs/operators'

import { PermissionsService } from '@zonar-ui/auth'

import {
  AppState,
  SetAccessStatus,
  SetUserEmail,
  SetUserProfileId,
} from '../../app.state'
import { IUserProfile } from '@zonar-ui/auth/lib/models/user-profile.model'

// The general idea of this service is to not cluster both
// permission service and auth service with bussiness logic.
// So in general:
// - auth service: communicate with identity provider for auth logic
// - permission service: only contain permission logic
// - wiring service: bussiness logic that depends on auth output

@Injectable({
  providedIn: 'root',
})
export class AuthWiringService {
  private hasZonarAdminProfile: boolean = false

  constructor(private store: Store, private permissionService: PermissionsService) {}

  /**
   * Initialize the service, connect to event streams from
   * auth and permission services
   */
  initialize() {
    const emptyPerms$ = this.permissionService.getIsFetchPermsSuccess().pipe(
      filter((result) => !result),
      map(() => null)
    )

    const userProfiles$ = merge(this.permissionService.getUserProfiles(), emptyPerms$)

    const zonarUserProfile$ = merge(this.permissionService.getZonarUser(), emptyPerms$)

    combineLatest([
      this.store.select(AppState.getActiveCompanyId),
      userProfiles$,
      zonarUserProfile$,
    ]).subscribe(([activeCompanyId, userProfiles, zonarUserProfile]) => {
      if (!userProfiles || this.hasZonarAdminProfile) {
        return
      }

      if (zonarUserProfile) {
        this.hasZonarAdminProfile = true
        this.setActiveUserProfile(zonarUserProfile)
        return
      }

      // Return if activeCompanyId is empty
      // Because we will set user profile for customer admin role based on activeCompanyId
      if (!activeCompanyId) {
        return
      }

      const activeUserProfile = userProfiles.find(
        (profile) => profile.companyId === activeCompanyId
      )
      if (activeUserProfile) {
        this.setActiveUserProfile(activeUserProfile)
      }
    })
  }

  private setActiveUserProfile(activeUserProfile: IUserProfile) {
    this.store.dispatch(new SetUserProfileId(activeUserProfile.id))
    this.store.dispatch(new SetUserEmail(activeUserProfile.companyEmail))
    this.store.dispatch(new SetAccessStatus(this.hasZonarAdminProfile))
  }
}
